import React, { Component } from 'react';

import styles from './newsletter.module.css';

class Newsletter extends Component {
	state = {
		email: '',
	};

	handeChange = e => {
		const value = e.target.value;
		this.setState({ email: value });
	};

	render() {
		return (
			<section className={styles.section}>
				<div className={styles.wrapper}>
					<h3 className={styles.h3}>
						สนใจติดตามข่าวสาร โปรโมชั่นใหม่ๆ ลงทะเบียนได้ที่นี่
					</h3>
					<div>
						<form
							className={styles.form}
							name="newsletter"
							method="POST"
							data-netlify="true"
							netlify-honeypot="bot-field"
						>
							<input type="hidden" name="bot-field" />
							<input type="hidden" name="form-name" value="newsletter" />
							<input
								className={styles.input}
								type="email"
								name="email"
								value={this.state.email}
								placeholder="กรอกอีเมลล์ของท่าน"
								onChange={this.handeChange}
								required
								minLength="10"
							/>
							<button className={styles.button} type="submit">
								ยืนยัน
							</button>
						</form>
					</div>
				</div>
			</section>
		);
	}
}

export default Newsletter;
