import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Product from './product';

import styles from './products.module.css';

function Products() {
	return (
		<StaticQuery
			query={graphql`
				query AllProducts {
					allMarkdownRemark(sort: { fields: frontmatter___id, order: ASC }) {
						nodes {
							frontmatter {
								id
								path
								thumbnail
								title
								size
								stock
								price
								ribbon
								ribbonActive
							}
						}
					}
				}
			`}
			render={data => {
				const productNodes = data.allMarkdownRemark.nodes;
				return (
					<section className={styles.section} id="products">
						<h2 className={styles.heading} style={{ color: '#888' }}>
							ขอแนะนำ
						</h2>
						<h2 className={styles.heading}>ผลิตภัณฑ์ของเรา</h2>
						<div className={styles.wrapper}>
							{productNodes.map(node => (
								<Product
									key={node.frontmatter.path}
									path={node.frontmatter.path}
									id={node.frontmatter.id}
									thumbnail={node.frontmatter.thumbnail}
									title={node.frontmatter.title}
									size={node.frontmatter.size}
									stock={node.frontmatter.stock}
									price={node.frontmatter.price}
									ribbon={node.frontmatter.ribbon}
									ribbonActive={node.frontmatter.ribbonActive}
								/>
							))}
						</div>
					</section>
				);
			}}
		/>
	);
}

export default Products;
