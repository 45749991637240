import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import Products from '../components/products';
import Newsletter from '../components/newsletter';

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<Banner />
		<Products />
		{/* <Newsletter /> */}
	</Layout>
);

export default IndexPage;
