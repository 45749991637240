import React from 'react';
import ScrollTo from 'gatsby-plugin-smoothscroll';

import styles from './banner.module.css';

function Banner() {
	return (
		<section className={styles.section}>
			<img
				className={styles.image}
				src="/images/banner.jpg"
				alt="Summer sale"
			/>
			<div className={styles.content}>
				<div className={styles.discount}>
					<hr className={styles.hr} />
					<span>สินค้า OTOP 5 ดาว</span>
					<hr className={styles.hr} />
				</div>
				<h1 className={styles.h1}>มาลัยสมุนไพรบำรุงผม</h1>
				<h2 className={styles.h2}>ยินดีต้อนรับ</h2>
				<button className={styles.button} onClick={() => ScrollTo('#products')}>
					<span>ดูผลิตภัณฑ์</span>
				</button>
			</div>
		</section>
	);
}

export default Banner;
