import React from 'react';
import styles from './product.module.css';
import { Link } from 'gatsby';

function Product({
	id,
	path,
	thumbnail,
	title,
	size,
	stock,
	price,
	ribbon,
	ribbonActive,
}) {
	const stockInfo = stock < 10 ? `Only ${stock} items left` : 'มีสินค้า';
	return (
		<article className={styles.article} data-item-id={id}>
			<Link to={path}>
				<div className={styles.thumbnailWrapper}>
					<img className={styles.thumbnail} src={thumbnail} alt={title} />
					{ribbonActive ? (
						<span className={styles.ribbon}>{ribbon.toUpperCase()}</span>
					) : null}
				</div>
				<div className={styles.content}>
					<div className={styles.topRow}>
						<h3 className={styles.title} data-item-title={title.toLowerCase()}>
							{title.toUpperCase()}
						</h3>
						<div data-item-size={size}>({size}ml)</div>
					</div>
					<small>{stockInfo}</small>
					<div className={styles.priceWrapper}>
						<span className={styles.currency}>฿</span>
						<span data-item-price={price}>{price}</span>
					</div>
				</div>
			</Link>
		</article>
	);
}

export default Product;
